import './App.css';
import HeroSection from './Components/HeroSection';
import Projects from './Components/Projects';
import Intro from './Components/Intro';
import Navbar from './Components/Navbar';
import { useEffect, useState } from 'react';
import ParticlesBG from './Components/ParticlesBG';
import Sidebar from './Components/Sidebar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Fragment } from 'react';
import Skills from './Components/Skills';
import Contact from './Components/Contact';
function App() {

  const [CamPosition,setCamPosition]=useState()
  const [FOV,setFOV]=useState(50)
  const [Option,setOption]=useState(-1)

  useEffect(()=>
  {
    const opt=JSON.parse(localStorage.getItem('Option'))
    setOption(opt)
  },[])

  return (
    <div class='snap-y snap-mandatory w-full min-h-screen '>
      <Sidebar Option={Option}/>
      <div class='fixed w-full h-full flex justify-end items-center bg-[#03031d] z-10'>
        <ParticlesBG/>
      </div>
      <Navbar/>

      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path='/' Component={HeroSection}/>
            <Route exact path='/profile' Component={Intro}/>
            <Route exact path='/skills' Component={Skills}/>
            <Route exact path='/projects' Component={Projects}/>
            <Route exact path='/contact' Component={Contact} />
          </Routes>
        </Fragment>
      </BrowserRouter>

    </div>
  );
}

export default App;
