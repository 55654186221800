/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 pc.gltf --transform 
Files: pc.gltf [12.3MB] > pc-transformed.glb [1.61MB] (87%)
Author: Yolala1232 (https://sketchfab.com/Yolala1232)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/gaming-desktop-pc-blend-file-9468eae06d5e464eaebe3a7ea73ea7c7
Title: Gaming Desktop PC (BLEND FILE)
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/pc-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Object_4.geometry} material={materials['Material.063']} position={[-0.61, -0.006, 1.561]} rotation={[-Math.PI, 0, Math.PI]} scale={[-3.689, 0.26, 7.316]} />
      <mesh geometry={nodes.Object_6.geometry} material={materials['Material.063_0']} position={[-0.859, 1.997, -3.537]} scale={[1.735, 1.573, 0.9]} />
      <mesh geometry={nodes.Object_14.geometry} material={materials.PaletteMaterial001} position={[0.742, 0.512, -2.629]} rotation={[Math.PI / 2, 0, 0]} scale={[0.061, 0.023, 0.063]} />
      <mesh geometry={nodes.Object_22.geometry} material={materials['Material.063_1']} position={[-1.655, 2.241, -4.408]} rotation={[-Math.PI, 0, Math.PI]} scale={[-0.894, 1.064, 0.007]} />
      <mesh geometry={nodes.Object_42.geometry} material={materials['Material.063_2']} position={[-0.961, 0.788, -3.634]} scale={[1.607, 0.343, 0.739]} />
      <mesh geometry={nodes.Object_56.geometry} material={materials['Material.063_3']} position={[-2.597, 2.848, -4.225]} rotation={[-Math.PI, 0, Math.PI / 2]} scale={[0.265, 0.318, 0.309]} />
      <mesh geometry={nodes.Object_62.geometry} material={materials['rgb-hdd-cover-aorus-v1_0']} position={[-2.356, 2.877, -4.239]} rotation={[Math.PI / 2, 0.651, 0]} scale={0.063} />
      <mesh geometry={nodes.Object_64.geometry} material={materials.PaletteMaterial003} position={[-1.655, 2.241, -4.408]} rotation={[-Math.PI, 0, Math.PI]} scale={[-0.894, 1.064, 0.007]} />
      <mesh geometry={nodes.Object_66.geometry} material={materials.PaletteMaterial004} position={[-1.655, 2.241, -4.408]} rotation={[-Math.PI, 0, Math.PI]} scale={[-0.894, 1.064, 0.007]} />
      <mesh geometry={nodes.Object_68.geometry} material={materials['Material.063_4']} position={[-0.423, 3.57, -3.542]} rotation={[0, 0, Math.PI]} scale={[1, 1, 1.337]} />
      <mesh geometry={nodes.Object_74.geometry} material={materials['rgb-hdd-cover-aorus-v1_1']} position={[-0.964, 2.71, -4.309]} scale={[-0.015, 0.443, 0.109]} />
      <mesh geometry={nodes.Object_76.geometry} material={materials['Material.063_5']} position={[-0.957, 2.71, -4.324]} scale={[-0.011, 0.444, 0.097]} />
      <mesh geometry={nodes.Object_110.geometry} material={materials['Material.063_6']} position={[-2.121, 0.794, -2.982]} scale={[0.355, 0.206, 0.052]} />
      <mesh geometry={nodes.Object_112.geometry} material={materials['rgb-hdd-cover-aorus-v1_2']} position={[-0.935, 0.832, -2.888]} rotation={[Math.PI / 2, 0, 0]} scale={0.369} />
      <mesh geometry={nodes.Object_114.geometry} material={materials['rgb-hdd-cover-aorus-v1_3']} position={[0.877, 0.836, -3.553]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={0.394} />
      <mesh geometry={nodes.Object_116.geometry} material={materials['rgb-hdd-cover-aorus-v1_4']} position={[-1.692, 1.144, -0.716]} rotation={[0, 0, -1.554]} scale={[0.194, 0.025, 0.194]} />
      <mesh geometry={nodes.Object_126.geometry} material={materials['Material.063_7']} position={[0.006, 0.704, -2.891]} rotation={[Math.PI / 2, 0, 0]} scale={0.452} />
      <mesh geometry={nodes.Object_128.geometry} material={materials['Material.063_8']} position={[-2.603, 0.78, -3.528]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={0.595} />
      <mesh geometry={nodes.Object_130.geometry} material={materials['Material.063_9']} position={[0.705, 3.571, -2.897]} rotation={[0, Math.PI / 2, 0]} scale={0.073} />
      <mesh geometry={nodes.Object_136.geometry} material={materials.PaletteMaterial002} position={[0.699, 3.567, -4.07]} scale={0.033} />
      <mesh geometry={nodes.Object_146.geometry} material={materials['Material.063_10']} position={[-1.614, 2.682, -3.682]} scale={[0.174, 0.401, 0.394]} />
      <mesh geometry={nodes.Object_148.geometry} material={materials['Material.063_11']} position={[-1.614, 2.682, -3.682]} scale={[0.174, 0.401, 0.394]} />
      <mesh geometry={nodes.Object_154.geometry} material={materials['rgb-hdd-cover-aorus-v1_5']} position={[-1.611, 2.687, -3.69]} rotation={[0, 0, -Math.PI / 2]} scale={[0.327, 0.342, 0.327]} />
      <mesh geometry={nodes.Object_164.geometry} material={materials['rgb-hdd-cover-aorus-v1_6']} position={[-1.159, 1.507, -4.352]} rotation={[Math.PI / 2, 0, 0]} scale={0.352} />
      <mesh geometry={nodes.Object_168.geometry} material={materials.PaletteMaterial005} position={[-1.655, 2.241, -4.408]} rotation={[-Math.PI, 0, Math.PI]} scale={[-0.894, 1.064, 0.007]} />
      <mesh geometry={nodes.Object_174.geometry} material={materials['Material.063_13']} position={[-2.095, 1.846, -3.701]} scale={[0.364, 0.106, 0.382]} />
      <mesh geometry={nodes.Object_176.geometry} material={materials['Material.063_14']} position={[-1.244, 1.846, -3.701]} scale={[0.364, 0.106, 0.382]} />
      <mesh geometry={nodes.Object_180.geometry} material={materials['Material.063_15']} position={[1.172, 0.256, -0.292]} scale={[1.244, 1.199, 1.541]} />
      <mesh geometry={nodes.Object_182.geometry} material={materials.PaletteMaterial006} position={[-2.293, 2.048, -3.16]} rotation={[Math.PI / 2, 0, 0]} scale={[0.104, 0.109, 0.104]} />
      <mesh geometry={nodes.Object_184.geometry} material={materials['Material.063_16']} position={[-1.272, 2.001, -3.706]} scale={[1.281, 0.127, 0.524]} />
      <mesh geometry={nodes.Object_190.geometry} material={materials['Material.063_17']} position={[-1.226, 2.137, -3.704]} scale={[1.333, 0.008, 0.545]} />
      <mesh geometry={nodes.Object_192.geometry} material={materials['Material.063_18']} position={[-1.824, 1.817, -3.158]} rotation={[Math.PI / 2, 0, 0]} scale={[0.086, 0.091, 0.086]} />
      <mesh geometry={nodes.Object_198.geometry} material={materials['Material.063_19']} position={[1.114, 0.324, -0.274]} rotation={[0, 0, -Math.PI]} scale={[-0.429, 0.229, 0.429]} />
      <mesh geometry={nodes.Object_210.geometry} material={materials['Material.063_20']} position={[1.114, 0.324, -0.274]} rotation={[0, 0, -Math.PI]} scale={[-0.429, 0.229, 0.429]} />
      <mesh geometry={nodes.Object_224.geometry} material={materials['Material.063_21']} position={[-2.58, 1.955, -3.715]} scale={[0.012, 0.177, 0.524]} />
      <mesh geometry={nodes.Object_244.geometry} material={materials['rgb-hdd-cover-aorus-v1_7']} position={[1.295, 0.466, -0.275]} rotation={[Math.PI / 2, 1.287, -Math.PI / 2]} scale={0.124} />
      <mesh geometry={nodes.Object_268.geometry} material={materials.PaletteMaterial007} position={[0.877, 3.082, -2.823]} rotation={[0, 0, -Math.PI / 2]} scale={0.057} />
      <mesh geometry={nodes.Object_282.geometry} material={materials['Material.063_22']} position={[-1.42, 1.648, 5.961]} rotation={[1.587, 0.121, 1.31]} scale={0.807} />
      <mesh geometry={nodes.Object_286.geometry} material={materials['Material.063_23']} position={[-1.537, 1.949, 0.83]} rotation={[1.576, 0.129, 1.57]} scale={1.13} />
      <mesh geometry={nodes.Object_288.geometry} material={materials['rgb-hdd-cover-aorus-v1_8']} position={[-1.362, 3.001, 3.004]} rotation={[Math.PI / 2, 0.07, -Math.PI / 2]} scale={[3.316, 3.316, 3.481]} />
      <mesh geometry={nodes.Object_290.geometry} material={materials['Material.063_24']} position={[-1.615, 4.494, 0.821]} rotation={[Math.PI / 2, 0.07, Math.PI / 2]} scale={0.237} />
      <mesh geometry={nodes.Object_308.geometry} material={materials.PaletteMaterial008} position={[1.477, 0.332, 4.031]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_310.geometry} material={materials.PaletteMaterial009} position={[1.477, 0.332, 4.031]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_346.geometry} material={materials['rgb-hdd-cover-aorus-v1_9']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_348.geometry} material={materials['rgb-hdd-cover-aorus-v1_10']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_354.geometry} material={materials['rgb-hdd-cover-aorus-v1_11']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_358.geometry} material={materials['rgb-hdd-cover-aorus-v1_12']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_364.geometry} material={materials['rgb-hdd-cover-aorus-v1_13']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_366.geometry} material={materials['rgb-hdd-cover-aorus-v1_14']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_380.geometry} material={materials['rgb-hdd-cover-aorus-v1_15']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_392.geometry} material={materials['rgb-hdd-cover-aorus-v1_16']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_402.geometry} material={materials['rgb-hdd-cover-aorus-v1_17']} position={[1.477, 0.326, 4.031]} rotation={[-Math.PI / 2, 0.09, Math.PI / 2]} scale={0.914} />
      <mesh geometry={nodes.Object_1081.geometry} material={materials['Material.063_25']} position={[1.016, 0.406, 2.44]} rotation={[1.571, 1.493, -1.571]} scale={0.219} />
      <instancedMesh args={[nodes.Object_6_1.geometry, materials.PaletteMaterial002, 5]} instanceMatrix={nodes.Object_6_1.instanceMatrix} />
      <instancedMesh args={[nodes.Object_7.geometry, materials['rgb-hdd-cover-aorus-v1'], 10]} instanceMatrix={nodes.Object_7.instanceMatrix} />
      <instancedMesh args={[nodes.Object_112_1.geometry, materials.PaletteMaterial001, 7]} instanceMatrix={nodes.Object_112_1.instanceMatrix} />
      <instancedMesh args={[nodes.Object_539.geometry, materials['rgb-hdd-cover-aorus-v1_11'], 6]} instanceMatrix={nodes.Object_539.instanceMatrix} />
      <instancedMesh args={[nodes.Object_634.geometry, materials['rgb-hdd-cover-aorus-v1_11'], 7]} instanceMatrix={nodes.Object_634.instanceMatrix} />
      <instancedMesh args={[nodes.Object_635.geometry, materials['rgb-hdd-cover-aorus-v1_17'], 6]} instanceMatrix={nodes.Object_635.instanceMatrix} />
    </group>
  )
}

useGLTF.preload('/pc-transformed.glb')
