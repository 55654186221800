import React from 'react'

function Sidebar2() {
  return (
    <div class='md:hidden w-1/2 absolute right-0 mt-14 mr-5 flex flex-col py-5 px-8 text-black/60  font-font2 text-lg bg-[#cf1edb] border-[2px] border-[#ececec] rounded-xl space-y-14'>
        <div class='w-full space-y-4 flex flex-col items-start '>
            <button class='w-full font-heading text-white text-center border-b-[1px] hover:text-[#03031d]' onClick={()=>{window.location.replace('/profile')}}>About</button>
            <button class='w-full font-heading text-white text-center border-b-[1px] hover:text-[#03031d]' onClick={()=>{window.location.replace('/projects')}}>Work</button>
            <button class='w-full font-heading text-white text-center hover:text-[#03031d]' onClick={()=>{window.location.replace('/contact')}}>Contact</button>
        </div>
    </div>
  )
}

export default Sidebar2