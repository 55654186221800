import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Transition from './Transition'
import Avatar from '../Models/Avatar'
import Name from '../assets/Name.png'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import '../App.css'

function Contact() {
    const {ref: myref, inView: myelem}= useInView()
    const [ShowPage,setShowPage]=useState(false)
    
    useEffect(()=>{
      localStorage.setItem('Option',JSON.stringify(4))
      setInterval(()=>{
        setShowPage(true)
      },4500)
    },[])
  
    return (
        <div ref={myref} class='w-full h-screen'>
            <Transition myref={myref} myelem={myelem}/>
            {
                ShowPage==true?
                <div>
                    <div class='absolute w-screen h-screen lg:flex hidden justify-end items-center '>
                        <div class='w-[60%] h-full flex items-center justify-center z-20 pb-24'>
                            <img src={Name} class='w-[58%] animate-spin-slow' />
                        </div>
                    </div>
                    <div class='w-screen h-screen flex justify-end items-center pt-24 pb-10'>
                        <div class='lg:w-[40%] w-full h-full flex py-5 pl-10 z-20'>
                            <div class='w-full h-fit flex flex-col border-[#cf1edb] p-5 space-y-8 border-[2px] rounded-xl '>
                                <div class='w-full flex flex-col space-y-1'>
                                    <text class='text-lg font-text text-gray-300'>GET IN TOUCH</text>
                                    <text class='font-heading text-6xl font-bold text-[#ececec]'>Contact.</text>
                                </div>
                                <div class='w-full h-fit flex flex-col space-y-2 text-white'>
                                    <text class='text-[#ececec]/80 text-lg font-heading'>Your Name</text>
                                    <input class='w-full rounded-lg py-2 px-3 outline-none bg-[#03033d]' placeholder='Whats your Name?'/>
                                </div>
                                <div class='w-full h-fit flex flex-col space-y-2 text-white'>
                                    <text class='text-[#ececec]/80 text-lg font-heading'>Your Email</text>
                                    <input class='w-full rounded-lg py-2 px-3 outline-none bg-[#03033d]' placeholder='Whats your Email?'/>
                                </div>
                                <div class='w-full h-fit flex flex-col space-y-2 text-white'>
                                    <text class='text-[#ececec]/80 text-lg font-heading'>Your Message</text>
                                    <textarea rows={3} class='w-full rounded-lg py-2 px-3 outline-none bg-[#03033d]' placeholder='What do you want to share with us?'/>
                                </div>
                                <button class='w-fit font-heading rounded-xl py-3 px-10 bg-[#cf1edb] text-white'>Send</button>
                            </div>
                        </div>
                        <div class='w-[60%] h-full hidden lg:flex items-center z-30'>
                            <Canvas camera={{fov:10}}>
                                <Stage castShadow environment='city' intensity={0.6}>
                                    <Avatar/>
                                </Stage>
                                <OrbitControls enableZoom={false} />
                            </Canvas>
                        </div>
                    </div>
                </div>
                :
                    <></>
            }
        </div>
    )
}

export default Contact