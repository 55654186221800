import { OrbitControls, Stage } from '@react-three/drei'
import React from 'react'
import PC from '../Models/Pc'
import { animate, useMotionValue } from 'framer-motion'
import {motion} from 'framer-motion-3d'
import { useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { framerMotionConfig } from './MotionConfig'

function PCSetup(props) {
  const CameraPositionX =useMotionValue()
  const CameraLookAtX=useMotionValue()

  const CameraPositionY =useMotionValue()
  const CameraLookAtY=useMotionValue()

  const scaleValue = 2;

  useEffect(()=>{
    animate(CameraPositionX,props.Check ? 10:30,{
        ...framerMotionConfig,
        duration:4
    })
    animate(CameraLookAtX,props.Check ? -10: 0,{
        ...framerMotionConfig,
        duration:4
    })

    animate(CameraPositionY,props.Check ? 0:5,{
        ...framerMotionConfig,
        duration:4
    })
    animate(CameraLookAtY,props.Check ? -1: 0,{
        ...framerMotionConfig,
        duration:4
    })

  },[props.Check])

  useFrame((state)=>{
    state.camera.position.x=CameraPositionX.get()
    state.camera.position.y=CameraPositionY.get()
    state.camera.lookAt(CameraLookAtX.get(),CameraLookAtY.get(),0)
  })
  return (
    <>
        <Stage castShadow environment='city' intensity={0.6}>
            <motion.group scale={2}>
                <PC/>
            </motion.group>
        </Stage>
    </>
  )
}

export default PCSetup