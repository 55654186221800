import React,{useCallback} from 'react'
import {Particles} from 'react-tsparticles'
import {loadFull} from 'tsparticles'

function ParticlesBG() {

    const particlesinit=useCallback(async(engine)=>{
        await loadFull(engine);
    },[])

    const particlesLoaded=useCallback(async()=>{},[])

  return (
    <Particles 
    class='w-full h-full fixed z-10'
    id='tsparticles' 
    init={particlesinit} 
    loaded={particlesLoaded}
    options={{
        fullScreen:{enable:false},
        background:{
            color:{
                value:''
            },
        },
        fpsLimit:120,
        interactivity:{
            events:{
                onClick:{
                    enable:false,
                    mode:'push'
                },
                onHover:{
                    enable:true,
                    mode:'repulse'
                },
                resize:true
            },
            modes:{
                push:{
                    quantity:90
                },
                repulse:{
                    distance:200,
                    duration:0.4
                }
            }
        },
        particles:{
            color:{
                value:'#cf1edb'
            },
            links:{
                color:'#a4189f',
                distance:150,
                enable:true,
                opacity:0.5,
                width:1
            },
            collisions:{
                enable:true
            },
            move:{
                directions:'none',
                enable:true,
                outModes:{
                    default:'bounce'
                },
                random:false,
                speed:0.8,
                straight:false
            },
            number:{
                density:{
                 enable:true,
                 area:800   
                },
                value:150
            },
            opacity:{
                value:0.5
            },
            shape:{
                type:'circle'
            },
            size:{
                value:{min:1,max:5}
            }
        },
        detectRetina:true
    }} 
    />
  )
}

export default ParticlesBG