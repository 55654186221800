import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

function Transition(props) {

const myAnimation=useAnimation()
const myAnimation2=useAnimation()
const myAnimation3=useAnimation()

async function CallTransition()
{
    if(props.myelem==true)
    {
        const FirstSetPromise = Promise.all([
            myAnimation.start({
                x:0,
                transition:{
                    type:'spring',duration:1
                },
            }),
            myAnimation2.start({
                x:0,
                transition:{
                    type:'spring',duration:1
                },
            }),
            myAnimation3.start({
                x:0,
                transition:{
                    type:'spring',duration:1
                },
            }),
          ]);

          await FirstSetPromise

          const SecondSetPromise = Promise.all([
            myAnimation.start({
                x:'-100vw',
                transition:{
                    type:'spring',duration:1
                },
            }),
            myAnimation2.start({
                x:'-100vw',
                transition:{
                    type:'spring',duration:4
                },
            }),
            myAnimation3.start({
                x:'-100vw',
                transition:{
                    type:'spring',duration:7
                },
            }),
          ]);

          await SecondSetPromise

    }
    if(props.myelem==false)
    {
        myAnimation.start({
            x:'-100vw'
        })
        
        myAnimation2.start({
            x:'-100vw'
        })

        myAnimation3.start({
            x:'-100vw'
        })
    }
}

useEffect(()=>{
    CallTransition()
},[props.myelem])

  return (
    <>
    <motion.div animate={myAnimation} class='w-screen h-screen fixed bg-[#2A0944] z-[999]'>

    </motion.div>
    <motion.div animate={myAnimation2} class='w-screen h-screen fixed bg-[#3F0071] z-[980]'>

    </motion.div>
    <motion.div animate={myAnimation3} class='w-screen h-screen fixed bg-[#610094] z-[970]'>

    </motion.div>
    </>
  )
}

export default Transition