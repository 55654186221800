import { MeshDistortMaterial, OrbitControls, PerspectiveCamera, RenderTexture, Sphere, SpotLight, Stage, Text } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import React from 'react'
import {BiToggleLeft} from 'react-icons/bi'
import PC from '../Models/Pc'
import Transition from './Transition'
import { useInView } from 'react-intersection-observer'
import { useState } from 'react'
import { useEffect } from 'react'
import { MotionConfig, animate, useMotionValue } from 'framer-motion'
import PCSetup from './PCSetup'
import { framerMotionConfig } from './MotionConfig'

function HeroSection() {
  const {ref: myref, inView: myelem}= useInView()
  const [ShowPage,setShowPage]=useState(false)

  const [Check,setCheck]=useState(false)

  function RedirectProfile()
  {
    setCheck(!Check)
    setInterval(()=>{
      window.location.replace('/profile')
    },1500)
  }

  function RedirectSkill()
  {
    setCheck(!Check)
    setInterval(()=>{
      window.location.replace('/skills')
    },1500)
  }
  
  useEffect(()=>{
    localStorage.setItem('Option',JSON.stringify(0))
    setInterval(()=>{
      setShowPage(true)
    },4500)
  },[])
  
  return (
    <MotionConfig transition={{
      ...framerMotionConfig
    }}>
      <div ref={myref} class='min-h-screen'>
        <Transition myref={myref} myelem={myelem}/>
        {
          ShowPage==true?
          <div class={Check==false?'w-full h-screen flex flex-col lg:pl-32 lg:pt-5 pt-24 pb-8':'w-full h-screen flex flex-col lg:pl-32 pt-24'}>
            <div class='w-full h-full flex flex-col lg:flex-row justify-between items-center'>
              {
                Check==false?
                <div class='lg:w-[55%] w-full lg:text-start text-center h-fit flex lg:items-start items-center space-x-5'>
                  <div class='w-fit h-full lg:flex hidden flex-col items-center z-20'>
                    <div class='w-5 h-5 rounded-full bg-[#3730aB]'></div>
                    <div class='w-1 h-40 bg-gradient-to-b from-[#3730aB] to-transparent '></div>
                  </div>
                  <div class='flex flex-col lg:space-y-5 space-y-10'>
                    <text class='text-[#ECECEC] font-heading text-7xl font-extrabold z-20'>Hi, I'm Abdullah</text>
                    <text class='font-text text-gray-300 text-xl z-20'>A full-stack developer excelling in web and mobile app development, adept at integrating AI, and skilled in crafting mesmerizing 3D visuals. </text>
                    <div class='flex lg:justify-start justify-center space-x-5'>
                      <button class='w-fit font-heading rounded-xl py-3 px-10 bg-[#cf1edb] text-white z-20' onClick={RedirectProfile}>My Profile</button>
                      <button class='w-fit font-heading rounded-xl py-3 px-10 border-[#cf1edb] border-[2px] bg-transparent text-white z-20' onClick={RedirectSkill}>Learn More</button>
                    </div>
                  </div>
                </div>
                :
                <></>
              }
              <div class={Check==false?'lg:w-2/3 w-full h-screen z-20':'w-full h-full z-20'}>
                <Canvas camera={{position:[100,50,-30],fov:50}}>
                    <PCSetup Check={Check}/>
                </Canvas>
              </div>
            </div>
          </div>
          :
          <></>
        }
      </div>
    </MotionConfig>
  )
}

export default HeroSection