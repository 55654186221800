import { Decal, Float, OrbitControls, PerspectiveCamera, Preload, RenderTexture, useTexture } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React from 'react'; // Import the missing components from 'three'


function SingleSkillSetup(props) {
  const dpTexture = useTexture(props.SkillIcon); // Load the texture here

  return (
    <>
    <Float speed={4} rotationIntensity={2} floatIntensity={5}>
      <ambientLight intensity={0.25} />
      <directionalLight position={[0,0,0.05]} />
      <mesh castShadow receiveShadow scale={2.2}>
        <icosahedronGeometry args={[0.7]} />
        <meshStandardMaterial color="#fff8eb" polygonOffset polygonOffsetFactor={-5} flatShading>
        </meshStandardMaterial>
        <Decal position={[0, 0, 1]} map={dpTexture} rotation={[2*Math.PI,0,6.25]} flatShading />
      </mesh>
    </Float>
    </>
  );
}

function SkillSetup(props) {
  return (
    <Canvas >
      <OrbitControls enableZoom={false} />
      <SingleSkillSetup SkillIcon={props.SkillIcon} /> {/* Render the SkillSetup component inside the Canvas */}
      <Preload all />
    </Canvas>
  );
}

export default SkillSetup;