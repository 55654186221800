/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 face3.gltf --transform 
Files: face3.gltf [21.65MB] > face3-transformed.glb [611.71KB] (97%)
Author: Lorenzo Penco (https://sketchfab.com/Eksmanen)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/thefuture-b5fda59eb51341a58da234c24074d2f0
Title: TheFuture
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/face3-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Object_2.geometry} material={materials.capelliHIcapelliHIlambert2SG} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.Object_3.geometry} material={materials.capelliHIlambert1SG} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.Object_5.geometry} material={materials.lambert6SG} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.Object_6.geometry} material={materials.lambert7SG} rotation={[-Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('/face3-transformed.glb')
