import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Transition from './Transition'
import {AiFillCaretLeft, AiFillCaretRight} from 'react-icons/ai'
import {IoMdOpen} from 'react-icons/io'

import pic1 from '../assets/FutureAI.png'
import pic2 from '../assets/truthlook.png'
import pic3 from '../assets/jared.png'
import pic4 from '../assets/faq.png'
import pic5 from '../assets/postbud.png'
import pic7 from '../assets/kassoma.png'
import pic6 from '../assets/metaseed.png'
import pic8 from '../assets/contact.png'

function Projects() {
  const {ref: myref, inView: myelem}= useInView()
  const [ShowPage,setShowPage]=useState(false)

  const [ProjectArr,setProjectArr]=useState([
    {name:'FuturAI',link:'https://futurai.co/',description:'FuturAI empowers you to explore the limitless possibilities of AI, from generating stunning visuals to answering your burning questions.',pic:pic1},
    {name:'Truthlook',link:'https://truthlook.com/',description:'Our unbiased search engine that utilizes advanced algorithms to scan content and provide relevant results. ',pic:pic2},
    {name:'MenuMatch',link:'https://lakeland.pages.dev/',description:'MenuMatch revolutionizes the foodservice industry by effortlessly combining the power of AI and data-driven insights',pic:pic3},
    {name:'FAQGenerator',link:'https://faqgenerator.pages.dev/',description:'An AI-powered tool that involves category names and content pieces to generate Frequently Asked Quetions(FAQs) for the user.',pic:pic4},
    {name:'PostBud',description:'PostBud is a webapp that allows user to create engaging social media posts, advertising scripts and product/service description to solve the struggle of tradesmen.',pic:pic5},
    {name:'MetaSeed',description:'Your one-stop app for the latest insights and understanding of the ever-evolving virtual universe. Explore the world of cryptocurrencies, NFTs, and the IoT with ease through our user-friendly platform. ',pic:pic6},
    {name:'Kassoma IA',description:'Through personalized lessons, interactive exercises, and real-time feedback, our app ensures a seamless English learning experience, empowering users to improve their language proficiency and effectively. ',pic:pic7},
    {name:'Contactify',description:'Contactify is a CRUD based website in which keeping track of your contacts becomes a hassle-free experience, allowing you to stay organized and connected with ease.',pic:pic8},
    
  ])
  
  const [ProjectCheck,setProjectCheck]=useState(1)
  const [Hovering,setHovering]=useState(-1)

  useEffect(()=>{
    localStorage.setItem('Option',JSON.stringify(3))
    setInterval(()=>{
      setShowPage(true)
    },4500)
  },[])

  return (
    <div ref={myref} class='w-full h-screen'>
      <Transition myref={myref} myelem={myelem}/>
      {
        ShowPage==true?
        <div class='w-full h-full flex lg:flex-row flex-col justify-center items-center lg:space-x-10 space-y-10 lg:space-y-0 lg:pt-0 pt-52'>
          <div class='lg:w-[34%] w-full flex flex-col lg:space-y-3 space-y-8 pl-5 text-center lg:text-start'>
            <text class='text-[#ECECEC] font-heading text-6xl font-extrabold z-20'>Latest Projects</text>
            <text class='font-text text-gray-300 text-lg z-20'>Excited to showcase my recent web and mobile app development projects, spanning various industries and showcasing my growth as a developer.</text>          
          </div>
          <div class='lg:w-[60%] w-full h-full lg:pt-24 pb-10 flex flex-col items-center z-20 space-y-8'>
            <div class='w-full h-full flex flex-wrap lg:px-0 px-5'>
              {
                ProjectCheck==1?
                  ProjectArr.map((obj,index)=>
                    index<4?
                    <div class='lg:w-[57vh] w-full h-1/2 mr-2 mb-2 rounded-2xl border-[2px] hover:border-[3px] border-[#cf1edb] relative'>
                      <img src={obj.pic} class='sticky w-full h-full object-cover rounded-2xl '/>
                      <button onClick={()=>{window.open(`${obj.link}`,'_blank')}} class='absolute flex flex-col justify-end p-5 items-center space-y-5 top-0 left-0 w-full h-full hover:bg-gradient-to-t hover:from-[#cf1edb]/90 hover:to-black/70 text-transparent hover:text-white rounded-2xl' onMouseEnter={()=>{setHovering(index)}} onMouseLeave={()=>{setHovering(-1)}}>
                        <text class='font-heading lg:text-2xl text-lg font-bold'>{obj.name}</text>
                        <text class='font-text text-center lg:text-sm text-xs'>{obj.description}</text>
                        <button class={Hovering==index&&Hovering!=-1?'p-3 bg-white rounded-full':'hidden'}>
                          <IoMdOpen size='1.8rem' color='#000000'/>
                        </button>
                      </button>
                    </div>
                    :
                    <></>
                  )
                :
                  <></>
              }
              {
                ProjectCheck==2?
                  ProjectArr.map((obj,index)=>
                    index>3?
                    <div class='w-[57vh] h-1/2 mr-2 mb-2 rounded-2xl border-[2px] hover:border-[3px] border-[#cf1edb] relative'>
                      <img src={obj.pic} class='sticky w-full h-full object-cover rounded-2xl '/>
                      <button class='absolute flex flex-col justify-end p-5 items-center space-y-5 top-0 left-0 w-full h-full hover:bg-gradient-to-t hover:from-[#cf1edb]/90 hover:to-black/70 text-transparent hover:text-white rounded-2xl' onMouseEnter={()=>{setHovering(index)}} onMouseLeave={()=>{setHovering(-1)}}>
                        <text class='font-heading text-2xl font-bold'>{obj.name}</text>
                        <text class='font-text text-center text-sm'>{obj.description}</text>
                        <div class={Hovering==index&&Hovering!=-1?'w-fit px-5 py-1 bg-white rounded-full':'hidden'}>
                          <text class='font-heading text-black'>In progress</text>
                        </div>
                      </button>
                    </div>
                    :
                    <></>
                  )
                :
                  <></>
              }
            </div>
            <div class='w-full h-fit flex items-center text-white '>
              <div class='w-[45%]  flex justify-end mr-2'>
                <button class='rounded-full p-[0.2rem] bg-[#cf1edb]/60 hover:bg-[#cf1edb]' onClick={()=>{if(ProjectCheck==2){setProjectCheck(1)}}}>
                  <AiFillCaretLeft size='1.8rem' color='white'/>
                </button>
              </div>
              <div class='w-[45%] flex justify-start mr-2'>
                <button class='rounded-full p-[0.2rem] bg-[#cf1edb]/60 hover:bg-[#cf1edb]' onClick={()=>{if(ProjectCheck==1){setProjectCheck(2)}}}>
                  <AiFillCaretRight size='1.8rem' color='white'/>
                </button>
              </div>
            </div>
          </div>
        </div>
        :
        <></>
      }
    </div>
  )
}

export default Projects