/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 skull.gltf --transform 
Files: skull.gltf [14.24MB] > skull-transformed.glb [943.6KB] (93%)
Author: sorianicolasm (https://sketchfab.com/sorianicolasm)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/skull-with-radioactive-moss-ab1ebaa600314e35a29c17a6832d3147
Title: Skull with radioactive moss
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/skull-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.defaultMaterial.geometry} material={materials.DefaultMaterial} scale={0.227} />
    </group>
  )
}

useGLTF.preload('/skull-transformed.glb')
