import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Transition from './Transition'
import {motion} from 'framer-motion'
import SkillSetup from './SkillSetup'

import reactSkill from '../assets/reactSkill.png';
import nodeSkill from '../assets/nodeSkill.png';
import nextSkill from '../assets/nextSkill.png';
import flutterSkill from '../assets/flutterSkill.png';
import htmlSkill from '../assets/htmlSkill.png';
import jsSkill from '../assets/jsSkill.png';
import tailwindSkill from '../assets/tailwindSkill.png';
import cssSkill from '../assets/cssSkill.png'
import supaSkill from '../assets/supaSkill.png'
import ThreeSkill from '../assets/3Skill.png'

function Skills() {
    const {ref: myref, inView: myelem}= useInView()
    const [ShowPage,setShowPage]=useState(false)
    
    const [SkillArr,setSkillArr]=useState([
        {pic:reactSkill,color:'#75C2F6',text:'React',percent:'95%'},
        {pic:nodeSkill,color:'#7AA874',text:'Node / Express',percent:'90%'},
        {pic:nextSkill,color:'#9BA4B5',text:'Next',percent:'93%'},
        {pic:ThreeSkill,color:'gray',text:'Three js',percent:'87%'},
        {pic:flutterSkill,color:'#1D5D9B',text:'Flutter',percent:'85%'},
        {pic:htmlSkill,color:'#ED2B2A',text:'HTML',percent:'96%'},
        {pic:jsSkill,color:'#F0DE36',text:'Javascript',percent:'94%'},
        {pic:tailwindSkill,color:'#22B2DA',text:'Tailwind CSS',percent:'98%'},
        {pic:cssSkill,color:'#75C2Ff',text:'CSS',percent:'96%'},
        {pic:supaSkill,color:'#1A5D1A',text:'Supabase',percent:'88%'}
    ])

    const [SelectedSkill,setSelectedSkill]=useState(-1)


    useEffect(()=>{
      localStorage.setItem('Option',JSON.stringify(2))
      setInterval(()=>{
        setShowPage(true)
      },4500)
    },[])
    
    return (
        <div ref={myref}>
            <Transition myref={myref} myelem={myelem}/>
            {
                ShowPage==true?
                    <div class={SelectedSkill==-1?'w-full h-screen flex flex-col pt-24 space-y-10':'w-full h-screen flex flex-col pt-24 space-y-10'}>
                        <div class='w-full h-fit flex flex-col items-center z-20 space-y-3'>
                            <text class='text-[#ECECEC] font-heading text-7xl font-bold text-center'>Technical Skills</text>
                            <text class='font-text text-gray-300 text-lg text-center'>These are the technologies that I've worked with</text>
                        </div>
                        <div class='w-full h-full flex justify-center flex-wrap bg-transparent'>
                            {
                                SkillArr.map((obj,index)=>
                                    <div class='w-fit flex flex-col justify-end items-center'>
                                        <div style={{borderColor:obj.color}} class='w-fit flex flex-col items-center border-[2px] rounded-lg mr-5 mb-5 hover:shadow-white hover:shadow-lg hover:p-3 z-20' onMouseEnter={()=>{setSelectedSkill(index)}} onMouseLeave={()=>{setSelectedSkill(-1)}}>
                                            <div class='w-fit h-fit flex flex-col items-center p-3 '>
                                                <SkillSetup SkillIcon={obj.pic} />
                                                {
                                                     SelectedSkill!=-1&&SelectedSkill==index?
                                                     <></>
                                                     :
                                                     <text class='text-[#ececec] font-heading text-xl'>{obj.text}</text>
                                                }
                                                {
                                                    SelectedSkill!=-1&&SelectedSkill==index?
                                                        <>
                                                            <div class='w-full h-fit flex flex-col space-y-2'>
                                                                <div class='w-full flex justify-between text-[#ececec]/80 font-text '>
                                                                    <text>{obj.text}</text>
                                                                    <text>{obj.percent}</text>
                                                                </div>
                                                                <div class='w-full flex h-3 bg-white rounded-full'>
                                                                    <div style={{backgroundColor:obj.color,width:obj.percent}} className={` h-full rounded-full` }></div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                :
                    <></>
            }
        </div>
    )
}

export default Skills