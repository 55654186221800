/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 mask.gltf --transform 
Files: mask.gltf [41.93MB] > mask-transformed.glb [4.28MB] (90%)
Author: ali-rahimi-shahmirzadi (https://sketchfab.com/ali-rahimi-shahmirzadi)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/soulless-1a18f6f206b14073884e1a9c31c03cbc
Title: Soulless
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/mask-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Head1Mesh_lambert3_0.geometry} material={materials.lambert3}>
        <meshStandardMaterial color='#9A4B7C' />
      </mesh>
    </group>
  )
}

useGLTF.preload('/mask-transformed.glb')
