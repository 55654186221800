import React, { useState } from 'react'
import {FaHome} from 'react-icons/fa'
import {BiSolidUser,BiMailSend} from 'react-icons/bi'
import {GrProjects} from 'react-icons/gr'
import {GiSkills} from 'react-icons/gi'

function Sidebar(props) {

  function RedirectProfile()
  {
    
  }
  
  return (
    <div class='absolute right-0 top-1/3 flex flex-col bg-white/20 lg:mr-5 mr-2 px-3 space-y-8 rounded-full py-5 z-50'>
        <FaHome size='1.5rem' class={props.Option==0?'text-[#cf1edb]':'text-white hover:text-[#cf1edb]'} onClick={()=>{window.location.replace('/')}}/>
        <BiSolidUser size='1.5rem' class={props.Option==1?'text-[#cf1edb]':'text-white hover:text-[#cf1edb]'} onClick={()=>{window.location.replace('/profile')}}/>
        <GiSkills size='1.5rem' class={props.Option==2?'text-[#cf1edb]':'text-white hover:text-[#cf1edb]'} onClick={()=>{window.location.replace('/skills')}}/>
        <GrProjects size='1.5rem' class={props.Option==3?'bg-[#cf1edb]':'bg-white hover:bg-[#cf1edb]'} onClick={()=>{window.location.replace('/projects')}}/>
        <BiMailSend size='1.5rem' class={props.Option==4?'text-[#cf1edb]':'text-white hover:text-[#cf1edb]'} onClick={()=>{window.location.replace('/contact')}} />
    </div>
  )
}

export default Sidebar