import React from 'react'
import logo from '../assets/logo.png'
import { BiMenu } from 'react-icons/bi'
import { useState } from 'react'
import Sidebar2 from './Sidebar2'

function Navbar() {

  const [MenuCheck,setMenuCheck]=useState(false)

  return (
    <div class='fixed w-full h-fit flex justify-between lg:px-32 px-5 py-4 backdrop-blur-[2px] z-50'>
        <button class='flex items-center space-x-5' onClick={()=>{window.location.replace('/')}}>
            <img src={logo} class='w-12' />
            <text class='text-[#ECECEC] font-heading md:text-lg font-bold'>Abdullah Naeem | JS expertise </text>
        </button>
        <div class='md:flex hidden items-center space-x-10 text-[#ECECEC]/70 font-heading text-lg font-light'>
            <button class='hover:text-white' onClick={()=>{window.location.replace('/profile')}}>About</button>
            <button class='hover:text-white' onClick={()=>{window.location.replace('/projects')}}>Work</button>
            <button class='hover:text-white' onClick={()=>{window.location.replace('/contact')}}>Contact</button>
        </div>
        <div class='md:hidden flex'>
            <BiMenu size='2rem' class='hover:bg-gray-400/50 bg-white rounded-lg' onClick={()=>{setMenuCheck(!MenuCheck)}}/>   
        </div>
        {
            MenuCheck==true?
                <Sidebar2 />
            :
                <></>
        }
    </div>
  )
}

export default Navbar