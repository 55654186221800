import { OrbitControls, Stage } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import React, { useEffect, useState } from 'react'
import Mask from '../Models/Mask'
import Skull from '../Models/Skull'
import Face from '../Models/Face3'
import { useInView } from 'react-intersection-observer'
import Transition from './Transition'

function Intro() {
  const {ref: myref, inView: myelem}= useInView()
  const [ShowPage,setShowPage]=useState(false)

  const [IntroOption,setIntroOption]=useState(0)
  
  useEffect(()=>{
    localStorage.setItem('Option',JSON.stringify(1))
    setInterval(()=>{
      setShowPage(true)
    },4500)
  },[])

  return (
    <div ref={myref} class='w-full h-screen'>
      <Transition myref={myref} myelem={myelem}/>
      {
        ShowPage==true?
          <div class='w-full h-screen flex items-center lg:pr-32 lg:pt-20 pt-52 pb-5 bg-white z-50'>
            <div class='w-[45%] h-full hidden lg:flex flex-col pl-20 z-20'>
              <div class='w-1/2 h-1/3 flex'>
                <Canvas camera={{fov:10}}>
                    <Stage castShadow environment='city' intensity={0.6}>
                        <Mask/>
                    </Stage>
                    <OrbitControls enableZoom={false} autoRotate autoRotateSpeed={7}/>
                </Canvas>
              </div>
              <div class='w-full h-1/3'>
                <Canvas camera={{fov:10}}>
                    <Stage castShadow environment='city' intensity={0.6}>
                        <Face/>
                    </Stage>
                    <OrbitControls enableZoom={false} autoRotate autoRotateSpeed={7} reverseOrbit/>
                </Canvas>
              </div>
              <div class='w-1/2 h-1/3'>
                <Canvas camera={{fov:10}}>
                    <Stage castShadow environment='city' intensity={0.6}>
                        <Skull/>
                    </Stage>
                    <OrbitControls enableZoom={false} autoRotate autoRotateSpeed={7}/>
                </Canvas>
              </div>
            </div>
            <div class='lg:w-[55%] w-full h-fit flex space-x-5 z-20'>
              <div class='w-fit h-full md:flex hidden flex-col items-center z-20'>
                <div class='w-5 h-5 rounded-full bg-[#3730aB]'></div>
                <div class='w-1 h-80 bg-gradient-to-b from-[#3730aB] to-transparent '></div>
              </div>
              <div class='w-full h-fit flex flex-col space-y-10'>
                <div class='flex flex-col space-y-2 lg:items-start items-center'>
                  <text class='text-[#ECECEC]/50 text-lg'>Introduction</text>
                  <text class='text-[#ECECEC] font-heading text-7xl font-extrabold lg:text-start text-center'>Build user-friendly experiences</text>
                </div>
                <text class='font-text text-gray-300 text-xl text-center lg:text-start'>I specialize in creating delightful, intuitive user experiences that consistently satisfy clients. With a proven track record and a passion for innovative technology, I craft seamless solutions that leave users thrilled.</text>
                
                <div class='flex text-[#ECECEC] text-lg font-heading space-x-10 '>
                  <button class='flex flex-col' onClick={()=>{setIntroOption(0)}}>
                    <text class={IntroOption==0?'text-[#cf1edb]':'hover:text-[#cf1edb]'}>Statistics</text>
                    <div class={IntroOption==0?'w-full h-[0.2rem] bg-[#cf1edb]':'w-10 h-[0.2rem] bg-[#ECECEC]'}></div>
                  </button>
                  <button class='flex flex-col' onClick={()=>{setIntroOption(1)}}>
                    <text class={IntroOption==1?'text-[#cf1edb]':'hover:text-[#cf1edb]'}>Experience</text>
                    <div class={IntroOption==1?'w-full h-[0.2rem] bg-[#cf1edb]':'w-10 h-[0.2rem] bg-[#ECECEC]'}></div>
                  </button>
                  <button class='flex flex-col' onClick={()=>{setIntroOption(2)}}>
                    <text class={IntroOption==2?'text-[#cf1edb]':'hover:text-[#cf1edb]'}>Credentials</text>
                    <div class={IntroOption==2?'w-full h-[0.2rem] bg-[#cf1edb]':'w-10 h-[0.2rem] bg-[#ECECEC]'}></div>
                  </button>
                </div>
                
                {
                  IntroOption==0?
                    <div class='flex lg:flex-row flex-col w-full h-[20vh] items-center lg:space-x-16 lg:space-y-0 space-y-10'>
                      <div class='flex flex-col space-y-2 items-center w-fit'>
                        <text class='bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent text-6xl font-bold'>5+</text>
                        <text class='text-center font-text font-medium text-gray-300 text-lg'>YEARS OF EXPERIENCE</text>
                      </div>
                      <div class='lg:h-20 h-1 lg:w-1 w-20 bg-gray-400'>
                      </div>
                      <div class='flex flex-col space-y-2 w-fit items-center'>
                        <text class='bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent  text-6xl font-bold'>10+</text>
                        <text class='text-center font-text text-gray-300 text-lg'>SATISFIED CLIENTS</text>
                      </div>
                      <div class='lg:h-20 h-1 lg:w-1 w-20 bg-gray-400'>
                      </div>
                      <div class='flex flex-col space-y-2 w-fit items-center'>
                        <text class='bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent  text-6xl font-bold'>30+</text>
                        <text class='text-center font-text text-gray-300 text-lg'>PROJECTS COMPLETED</text>
                      </div>
                    </div>
                    :
                      <></>
                }

                {
                  IntroOption==1?
                    <div class='flex flex-col justify-center h-[20vh] space-y-4'>
                      <div class='flex space-x-2 text-gray-300 font-text lg:text-xl md:text-base text-sm'>
                        <text>Full Stack Developer</text>
                        <text>-</text>
                        <text>Fiver</text>
                        <text>-</text>
                        <text class='font-heading bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent'>January 2023 onwards</text>
                      </div>
                      <div class='flex space-x-2 text-gray-300 font-text lg:text-xl md:text-base text-sm'>
                        <text>Bootcamp Trainee (IOT)</text>
                        <text>-</text>
                        <text>Grandeur</text>
                        <text>-</text>
                        <text class='font-heading bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent'>June 2022 - August 2022</text>
                      </div>
                      <div class='flex space-x-2 text-gray-300 font-text lg:text-xl md:text-base text-sm'>
                        <text>Bootcamp Trainee (ROR)</text>
                        <text>-</text>
                        <text>Devsinc</text>
                        <text>-</text>
                        <text class='font-heading bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent'>December 2022 - February 2023</text>
                      </div>
                    </div>
                  :
                  <></>
                }

                {
                  IntroOption==2?
                  <div class='flex flex-col justify-center h-[20vh] space-y-4'>
                    <div class='flex space-x-2 text-gray-300 font-text lg:text-xl md:text-base text-sm'>
                      <text>Bachelors in Computer Science</text>
                      <text>-</text>
                      <text>FAST NUCES, Lahore</text>
                      <text>-</text>
                      <text class='font-heading bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent'>2020-2024</text>
                    </div>
                    <div class='flex space-x-2 text-gray-300 font-text lg:text-xl md:text-base text-sm'>
                      <text>Pre-Engineering</text>
                      <text>-</text>
                      <text>PGC, Lahore</text>
                      <text>-</text>
                      <text class='font-heading bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent'>2018-2020</text>
                    </div>
                    <div class='flex space-x-2 text-gray-300 font-text lg:text-xl md:text-base text-sm'>
                      <text>Matriculation</text>
                      <text>-</text>
                      <text>GAB, Lahore</text>
                      <text>-</text>
                      <text class='font-heading bg-gradient-to-br from-[#FFB200] to-[#068FFF] bg-clip-text text-transparent'>2015-2018</text>
                    </div>
                </div>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
        :
        <></>
      }
    </div>
  )
}

export default Intro